export enum PromoBannerType {
  MARKET_COUNTDOWN = "MARKET_COUNTDOWN",
  MARKET_LIST = "MARKET_LIST",
  FAIRE_VALUES = "FAIRE_VALUES",
  FAIRE_DIRECT_INCENTIVES = "FAIRE_DIRECT_INCENTIVES",
  FIRST_ORDER_INCENTIVE = "FIRST_ORDER_INCENTIVE",
  SPECIAL_HOLIDAY_NET90 = "SPECIAL_HOLIDAY_NET90",
  OPENING_ORDER_INCENTIVES = "OPENING_ORDER_INCENTIVES",
  AMEX_INCENTIVE = "AMEX_INCENTIVE",
  BRAND_PREVIEW = "BRAND_PREVIEW",
  BRANDS_COUNTER = "BRANDS_COUNTER",
  WAREHOUSE_SALE = "WAREHOUSE_SALE",
  EMPLOYEE_GRANTED_FREE_FREIGHT = "EMPLOYEE_GRANTED_FREE_FREIGHT",
  POST_SIGNUP = "POST_SIGNUP",
  FAIRE_DIRECT_FIRST_ORDER_INCENTIVE = "FAIRE_DIRECT_FIRST_ORDER_INCENTIVE",
  INSIDER_INTERNATIONAL_LAUNCH_DAY = "INSIDER_INTERNATIONAL_LAUNCH_DAY",
  OPEN_WITH_FAIRE = "OPEN_WITH_FAIRE",
  POS_INTEGRATION = "POS_INTEGRATION",
  REFERRAL_FOLLOWUP = "REFERRAL_FOLLOWUP",
  MARKETPLACE_INCENTIVE = "MARKETPLACE_INCENTIVE",
  INSIDER_AUTO_ENROLL = "INSIDER_AUTO_ENROLL",
  CONDUCTOR_BANNERS = "CONDUCTOR_BANNERS",
}

type SetOfPromoBanners = Array<PromoBannerType | PromoBannerType[]>;

export const LOGGED_IN_PROMO_BANNERS: SetOfPromoBanners = [
  PromoBannerType.FAIRE_DIRECT_INCENTIVES,
  PromoBannerType.MARKET_COUNTDOWN,
  PromoBannerType.CONDUCTOR_BANNERS,
  PromoBannerType.FAIRE_VALUES,
  [
    PromoBannerType.FIRST_ORDER_INCENTIVE,
    PromoBannerType.INSIDER_AUTO_ENROLL,
    PromoBannerType.POS_INTEGRATION,
    PromoBannerType.OPEN_WITH_FAIRE,
    PromoBannerType.SPECIAL_HOLIDAY_NET90,
    PromoBannerType.OPENING_ORDER_INCENTIVES,
    PromoBannerType.AMEX_INCENTIVE,
    PromoBannerType.WAREHOUSE_SALE,
    PromoBannerType.REFERRAL_FOLLOWUP,
  ],
  PromoBannerType.EMPLOYEE_GRANTED_FREE_FREIGHT,
];

export const LOGGED_OUT_PROMO_BANNERS: SetOfPromoBanners = [
  PromoBannerType.MARKET_COUNTDOWN,
  [
    PromoBannerType.SPECIAL_HOLIDAY_NET90,
    PromoBannerType.BRANDS_COUNTER,
    PromoBannerType.AMEX_INCENTIVE,
  ],
];
